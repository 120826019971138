@import '~sass/breakpoints';
@import '~sass/color';
@import '~sass/font';

.mat-table {
    @extend .shadow;
    width:100%;
    font-family: $font !important;
    overflow: visible;
    border-radius: 4px;

    .mat-checkbox {
        .mat-checkbox-inner-container {
            width: 12px;
            height: 12px;
        }
    }

    .mat-row:last-child {
        border-radius: 0 0 4px 4px;
    }

    &.has-paging {
        .mat-row:last-child {
            border-radius: 0;
        }    
    }

    .mat-header-row {
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        border: 1px transparent;
        min-height: unset;

        .mat-header-cell {
            text-transform: uppercase;
        }
    }

    
    .mat-header-cell, .mat-cell {
        font-size: 12px;
        *:not(.mat-icon) {
            font-size: 12px;
        }
        line-height: 16px;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: left;

        &.center {
            justify-content: center;
            padding-left: 0;
            padding-right: 0;
        }
        
        &.right {
            justify-content: right;
        }
        
        &:last-of-type {
            border-right: 0;
        }
    }

    .mat-header-cell {
        font-weight: 700;
        height: 50px!important;
    }

    .mat-row {
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 1px solid $light-grey;

        &:hover:not(.no-hover-highlight) {
            background-color: $light-grey;
        }
    }

    .mat-cell {
        font-weight: normal;
        border: none;
        overflow: hidden;
    }


}

.mat-paginator {
    border-radius: 0 0 6px 6px;
    @extend .shadow;
}