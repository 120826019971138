@import '~sass/breakpoints';
@import '~sass/color';

app-dialog {
    height: 100%;
}

app-dialog.dialog-large-header-title .dialog-header-title {
    font-size: 32px;
}


.dialog-container {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px;
    top: 0;
    left: 0;

    h3.dialog-header-title {
        font-size: 18px;
        margin: 0;
        padding: 20px 0px 20px 20px;
    }

    .dialog-content {
        height: calc(100% - 160px);

        [body] {
            height: 100%;
        }

        flex: 1 1;
        padding: 0 2px;
        overflow: auto;
        padding: 10px 20px 0px 20px;
    }

    .borderless {
        padding: 0 !important;
        overflow: hidden !important;
    }

    .dialog-close {
        margin-right: 5px;
    }

    [footer-actions] {
        padding: 0px 20px 20px 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 12px;

        > button {
            width: 100%;
    
            @include media-breakpoint('gt-xs') {
                width: auto;
            }
        }

        &.padding-top-8 {
            padding-top: 8px;
        }
    }
}
