// Default system colors
$rag-red: #FF0000;
$rag-amber: #FF8900;
$rag-green: #80BE6C;
$rag-blue: #56a5f8;
$rag-red-light: #FFB1B1;
$rag-amber-light: #FFF1DA;
$rag-green-light: #D5F6E4;
$rag-blue-light: #DDEBF6;
$dark-grey: #B3ADB8;
$medium-grey: #D1D2DB;
$light-grey: #EEECF3;
$white: #FFFFFF;
$primary: #00142D;

$grey-900: #1B1C1C;
$grey-800: #353535;
$grey-700: #4E4F4F;
$grey-600: #676868;
$grey-500: #818181;
$grey-400: #9A9A9A;
$grey-350: #AAAAAA;
$grey-300: #B3B3B3;
$grey-200: #CCCDCD;
$grey-100: #E6E6E6;
$grey-050: #FAFAFA;


.shadow {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.shadow-light {
    box-shadow: 0 2px 2px 1px rgba(0,0,0,0.2);
}

.rag-red-text { color: $rag-red!important; }
.rag-red-bg { background-color: $rag-red!important; }
.rag-red-border { border-color: $rag-red!important; }
.rag-red-light-text { color: $rag-red-light };
.rag-red-light-bg { background-color: $rag-red-light };

.rag-amber-text { color: $rag-amber!important; }
.rag-amber-bg { background-color: $rag-amber!important; }
.rag-amber-border { border-color: $rag-amber!important; }
.rag-amber-light-text { color: $rag-amber-light };
.rag-amber-light-bg { background-color: $rag-amber-light };

.rag-blue-text { color: $rag-blue!important; }
.rag-blue-bg { background-color: $rag-blue!important; }
.rag-blue-border { border-color: $rag-blue!important; }
.rag-blue-light-text { color: $rag-blue-light };
.rag-blue-light-bg { background-color: $rag-blue-light };

.rag-green-text { color: $rag-green!important; }
.rag-green-bg { background-color: $rag-green!important; }
.rag-green-border { border-color: $rag-green!important; }
.rag-green-light-text { color: $rag-green-light };
.rag-green-light-bg { background-color: $rag-green-light };

.white-text { color: $white!important; }
.white-bg { background-color: $white!important; }
.white-border { border-color: $white!important; }

.dark-grey-text { color: $dark-grey!important; }
.dark-grey-bg { background-color: $dark-grey!important; }
.dark-grey-border { border-color: $dark-grey!important; }

.medium-grey-text { color: $medium-grey!important; }
.medium-grey-bg { background-color: $medium-grey!important; }
.medium-grey-border { border-color: $medium-grey!important; }

.light-grey-text { color: $light-grey!important; }
.light-grey-bg { background-color: $light-grey!important; }
.light-grey-border { border-color: $light-grey!important; }

.grey-050-bg { background-color: $grey-050; }
.grey-050-text { color: $grey-050; }
.grey-100-bg { background-color: $grey-100; }
.grey-100-text { color: $grey-100; }
.grey-200-bg { background-color: $grey-200; }
.grey-200-text { color: $grey-200; }
.grey-300-bg { background-color: $grey-300; }
.grey-300-text { color: $grey-300; }
.grey-350-bg { background-color: $grey-350; }
.grey-350-text { color: $grey-350; }
.grey-400-bg { background-color: $grey-400; }
.grey-400-text { color: $grey-400; }
.grey-500-bg { background-color: $grey-500; }
.grey-500-text { color: $grey-500; }
.grey-600-bg { background-color: $grey-600; }
.grey-600-text { color: $grey-600; }
.grey-700-bg { background-color: $grey-700; }
.grey-700-text { color: $grey-700; }
.grey-800-bg { background-color: $grey-800; }
.grey-800-text { color: $grey-800; }
.grey-900-bg { background-color: $grey-900; }
.grey-900-text { color: $grey-900; }

.greyscale {
    filter: grayscale(100%);
}

.drop-shadow-low { box-shadow: 0px  7px  20px rgba(0, 0, 0, 0.1); }
.drop-shadow { box-shadow: 0px  10px  40px rgba(0, 0, 0, 0.12); }
.drop-shadow-high { box-shadow: 0px  20px  60px rgba(0, 0, 0, 0.15); }

.lozenge {
    text-align: center;
    border: 1px solid;
    padding: 2px;
    border-radius: 20px;
    &.complete {
        @extend .rag-green-text;
        @extend .rag-green-border;
        @extend .rag-green-light-bg;
    }

    &.active {
        @extend .rag-blue-text;
        @extend .rag-blue-border;
        @extend .rag-blue-light-bg;
    }
    
    &.pending {
        @extend .rag-amber-text;
        @extend .rag-amber-border;
        @extend .rag-amber-light-bg;
    }
    &.rejected {
        @extend .rag-red-text;
        @extend .rag-red-border;
        @extend .rag-red-light-bg;
    }

    &.inactive {
        border-color: $dark-grey;
        color: $dark-grey;
        background-color: $light-grey;
    }
}

.connect-status {
 
    display: inline-block !important;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    color: $white;
    .status {
        color: $white;
    }

    &.pending {
        background-color: $rag-amber;
    }

    &.approved {
        background-color: $rag-green;
    }

    &.rejected {
        background-color: $rag-red;
    }
}
