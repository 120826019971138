
@import '~sass/color';

.mat-tooltip {
    background-color: $light-grey !important;
    opacity: 1;
    font-size: 13px;
    padding: 25px 30px !important;
    max-width: 400px !important;
    box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.3);
    line-height: 20px;
}
