@import '~sass/color';
@import '~sass/font';
@import '~sass/mixins';

.mat-checkbox,
.mat-card,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-list-item,
.mat-tab-group {
    *:not(mat-icon) {
        font-family: $font;
    }
}

.mat-icon {
    color: inherit;
    margin-top: 2px;
}

.mat-form-field {
    width: 100%;

    &.tertiary {
        .mat-form-field-infix {
            padding: 0px 0 6px 0;
            border-top: 11px solid transparent;
        }
    
        .mat-form-field-wrapper {
            padding: 0;
            margin: 0;
    
            input {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    .mat-form-field-infix {
        padding: 15px 0 13px 0 !important;
        border-top: 0;
    }
    
    .mat-form-field-wrapper {
        .mat-error {
            font-size: 10px !important;
        }

        font-size: 14px !important;
        line-height: 16px;
    }

    &:not(:disabled) {
        .mat-form-field-outline {
            .mat-form-field-outline-start {
               background-color: $white;
           }

           .mat-form-field-outline-gap { 
               background-color: $white;
           }
       
           .mat-form-field-outline-end { 
               background-color: $white;
           }
        }

    }

    &.mat-form-field-disabled {
        &.mat-focused .mat-form-field-label { color: $dark-grey; }
        &:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
            border-top: 1px solid $dark-grey;
        }

        .mat-form-field-outline .mat-form-field-outline-start {
            border-left: 1px solid $dark-grey;
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
        }

        .mat-form-field-outline .mat-form-field-outline-gap {
            border-bottom: 1px solid $dark-grey;
        }

        .mat-form-field-outline .mat-form-field-outline-end {
            border-right: 1px solid $dark-grey;
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
        }

        mat-label {
            color: $dark-grey;
        }
    }

    &.light {
        &:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
            border-top: 1px solid $dark-grey;
        }
        
        .mat-form-field-outline .mat-form-field-outline-start {
            border-left: 1px solid $dark-grey;
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
        }

        .mat-form-field-outline .mat-form-field-outline-gap {
            border-bottom: 1px solid $dark-grey;
        }

        .mat-form-field-outline .mat-form-field-outline-end {
            border-right: 1px solid $dark-grey;
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
        }
    }


    &:not(.mat-form-field-disabled):not(.light).mat-focused, &:not(.mat-form-field-disabled):not(.light):hover {
        .mat-form-field-outline {
            .mat-form-field-outline-start {
               border-left-width: 2px!important;
               border-top-width: 2px!important;
               border-bottom-width: 2px!important;
           }

           .mat-form-field-outline-gap { 
               border-bottom-width: 2px!important; 
           }
       
           .mat-form-field-outline-end { 
               border-right-width: 2px!important;
               border-top-width: 2px!important;
               border-bottom-width: 2px!important;
           }
        }

        &:not(.mat-form-field-should-float) {
            .mat-form-field-outline {
                .mat-form-field-outline-gap {
                    border-top-width: 2px!important;
                }
            }
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-label { color: $rag-red!important; }

        .mat-form-field-outline {
             .mat-form-field-outline-start {
                border-left: 1px solid $rag-red;
                border-top: 1px solid $rag-red;
                border-bottom: 1px solid $rag-red;
            }

            .mat-form-field-outline-gap { 
                border-bottom: 1px solid $rag-red; 
            }
        
            .mat-form-field-outline-end { 
                border-right: 1px solid $rag-red;
                border-top: 1px solid $rag-red;
                border-bottom: 1px solid $rag-red;
            }

            .mat-form-field-outline-start {
                background-color: $white;
            }
        
            .mat-form-field-outline-gap { 
                background-color: $white; 
            }
        
            .mat-form-field-outline-end {
                background-color: $white;
            }
        }
        
        &:not(.mat-form-field-should-float) {
            .mat-form-field-outline {
                .mat-form-field-outline-gap {
                    border-top: 1px solid $rag-red;
                }
            }
        }
    }
    
    button {
        position: absolute;
        right: 0;
        top: 2px;
    }
}

.mat-tab-group {
    @extend .shadow;
    border-radius: 6px;
    background-color: $white;
    overflow: hidden;

    .mat-tab-label {
        font-weight: 700;
        opacity: 1;

        &.mat-tab-disabled {
            color: $dark-grey!important;
        }
    }
}

.mat-checkbox {
    .mat-checkbox-inner-container {
        width: 24px;
        height: 24px;
    }
}

.mat-checkbox-label {
    word-spacing: normal!important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
    background-color: transparent!important;
}

.mat-slide-toggle span {
    font-family: "Inter", sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
}

.mat-slide-toggle.mat-checked {
    &.mat-slide-toggle-white.mat-primary {
        .mat-slide-toggle-thumb {
            background-color: $white!important;
        }
        .mat-slide-toggle-bar {
            background-color: rgba(0,0,0,.38)!important;
        }
    } 
    .mat-slide-toggle-thumb {
        background-color: $rag-green!important;
    }
    .mat-slide-toggle-bar {
        background-color: rgba($rag-green,.38)!important;
    }
}

.mat-slide-toggle.mat-slide-toggle-white .mat-slide-toggle-label {
    color: $white;
}

.mat-slide-toggle.mat-slide-toggle-reverse .mat-slide-toggle-label {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.mat-progress-bar.green {
    height: 6px !important;

    .mat-progress-bar-fill::after {
        background-color: $rag-green;
    }

    .mat-progress-bar-buffer {
        background-color: $light-grey;
    }
}

.cdk-overlay-pane {
    max-width: 100%!important;
    @include media-breakpoint('lt-sm') {
        position: fixed;

        width: 100%!important;
        height: 100%!important;
    }
}

.mat-dialog-content {
    margin: 0!important;
}

.mat-dialog-container {
    padding: 0!important;
}

@include media-breakpoint('lt-sm') {
    .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
        transform: none!important;
        left: 16px!important;
        right: 16px!important;
        bottom: 0!important;
        width: auto!important;
        height: auto!important;
        max-height: 80%!important;
        .mat-select-panel {
            min-width: calc(100% - 30px)!important;
        }
    }
}

.mat-paginator-sticky {
    bottom: 0px;
    position: sticky;
    z-index: 10;
}

.mat-select-arrow-wrapper {
    transform: none !important;
}

.mat-form-field-subscript-wrapper {
    margin-top: .4em !important;
}

.mat-table {
    .mat-header-cell {
        .mat-sort-header-stem {
            display: none;
        }
        .mat-sort-header-indicator {
            top: 3px;
        }
        &[aria-sort='descending'] {
            .mat-sort-header-indicator {
                top: -3px;
            }
        }
    }
}