@import './color';

button.mat-raised-button {
    border-radius: 6px;
    box-shadow: none!important;
    min-height: unset;
    min-width: unset;
    width: unset;
    vertical-align: middle;
    line-height: 18px;
    border-width: 2px;
    border-style: solid;
    font-weight: 700;
    outline: none;

    font-size: 14px;
    padding: 0px 16px;
    height: 42px;

    * {
        font-size: inherit;
    }
    mat-icon {
        height: 20px;
        width: 20px;
        vertical-align: top;
        font-size: 20px;
        line-height: 16px;
    }

    &.xsmall {
        font-size: 10px;
        padding: 0px 8px;
        height: 24px;
        line-height: 14px;
        mat-icon {
            height: 14px;
            width: 14px;
            vertical-align: top;
            font-size: 14px;
            line-height: 12px;
        }
    }

    &.small {
        font-size: 12px;
        padding: 0px 12px;
        height: 36px;
        line-height: 16px;

        mat-icon {
            height: 18px;
            width: 18px;
            vertical-align: top;
            font-size: 18px;
            line-height: 14px;
        }
    }

    &.large {
        font-size: 20px;
        padding: 0px 40px;
        height: 48px;

        mat-icon {
            height: 25px;
            width: 25px;
            vertical-align: top;
            font-size: 25px;
            line-height: 22px;
        }
    }

    &.ghost {
        background-color: $white;

        &.rag-green:not(:disabled) {
            color: $rag-green !important;
            border-color: $rag-green !important;
        }

        &.rag-amber:not(:disabled) {
            color: $rag-amber !important;
            border-color: $rag-amber !important;
        }

        &.rag-red:not(:disabled) {
            color: $rag-red !important;
            border-color: $rag-red !important;
        }

        &.rag-blue:not(:disabled) {
            color: $rag-blue !important;
            border-color: $rag-blue !important;
        }
    }

    &.borderless {
        background-color: $white;
        border-color: $white;
    }

    &:disabled {
        background-color: $light-grey!important;
        border-color: $light-grey!important;
        color: $dark-grey!important;

        &.ghost {
            background-color: $white!important;
            border-color: $light-grey!important;
            color: $dark-grey!important;
        }

        &.borderless {
            background-color: $white!important;
            border-color: $white!important;
            color: $dark-grey!important;
        }
    }

    &.lozenge {
        border-radius: 100px;
        padding: 8px 20px;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        height: 36px;
        vertical-align: middle;

        mat-icon {
            padding-left: 8px;
            height: 14px;
            width: 14px;
            vertical-align: top;
            font-size: 14px;
        }
    }
}

/* TODO: Connect - do we need these fab buttons? */
// Mini fab buttons (document download button on reg entry details)
button.mat-mini-fab {
    height: 30px;
    width: 30px;

    .mat-button-wrapper {
        padding: 0;

        .mat-icon {
            font-size: 18px;
            line-height: 18px;
        }
    }    
}

.tabs {        
    margin-bottom: -1px;
    border-bottom: 1px solid $dark-grey;

    .tab {
        &:not(:first-child) {
            margin-left: -1px;
        }
        @include animate;
        margin-bottom: -1px;
        cursor: pointer;
        font-size: 14px;
        color: $dark-grey;
        border: 1px solid $dark-grey;
        background-color: $light-grey;
        border-radius: 6px 6px 0 0;
        min-width: 158px;
        height: 41px;
        line-height: 41px;
        padding: 0 16px;
        vertical-align: middle;
        text-align: center;

        &.active {
            font-weight: 700;
        }
    }
}