@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://fonts.googleapis.com/css?family=Inter:Light");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://fonts.googleapis.com/css?family=Inter:Light");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://fonts.googleapis.com/css?family=Inter:Light");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://fonts.googleapis.com/css?family=Inter:Light");
@import url("https://fonts.googleapis.com/css?family=Poppins");
.shadow, .mat-paginator, .mat-table, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-text, .lozenge.rejected {
  color: #FF0000 !important;
}

.rag-red-bg {
  background-color: #FF0000 !important;
}

.rag-red-border, .lozenge.rejected {
  border-color: #FF0000 !important;
}

.rag-red-light-text {
  color: #FFB1B1;
}

.rag-red-light-bg, .lozenge.rejected {
  background-color: #FFB1B1;
}

.rag-amber-text, .lozenge.pending {
  color: #FF8900 !important;
}

.rag-amber-bg {
  background-color: #FF8900 !important;
}

.rag-amber-border, .lozenge.pending {
  border-color: #FF8900 !important;
}

.rag-amber-light-text {
  color: #FFF1DA;
}

.rag-amber-light-bg, .lozenge.pending {
  background-color: #FFF1DA;
}

.rag-blue-text, .lozenge.active {
  color: #56a5f8 !important;
}

.rag-blue-bg {
  background-color: #56a5f8 !important;
}

.rag-blue-border, .lozenge.active {
  border-color: #56a5f8 !important;
}

.rag-blue-light-text {
  color: #DDEBF6;
}

.rag-blue-light-bg, .lozenge.active {
  background-color: #DDEBF6;
}

.rag-green-text, .lozenge.complete {
  color: #80BE6C !important;
}

.rag-green-bg {
  background-color: #80BE6C !important;
}

.rag-green-border, .lozenge.complete {
  border-color: #80BE6C !important;
}

.rag-green-light-text {
  color: #D5F6E4;
}

.rag-green-light-bg, .lozenge.complete {
  background-color: #D5F6E4;
}

.white-text {
  color: #FFFFFF !important;
}

.white-bg {
  background-color: #FFFFFF !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EEECF3 !important;
}

.light-grey-bg {
  background-color: #EEECF3 !important;
}

.light-grey-border {
  border-color: #EEECF3 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.greyscale {
  filter: grayscale(100%);
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.lozenge {
  text-align: center;
  border: 1px solid;
  padding: 2px;
  border-radius: 20px;
}
.lozenge.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EEECF3;
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #FF8900;
}
.connect-status.approved {
  background-color: #80BE6C;
}
.connect-status.rejected {
  background-color: #FF0000;
}

*:not(.mat-icon) {
  font-family: "Inter", sans-serif;
}

html {
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  height: 100%;
  margin: 0;
}
body app-root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}

h1.border,
h2.border,
h3.border,
h4.border,
a {
  text-decoration: underline;
  cursor: pointer;
}

section {
  margin-bottom: 30px;
}

.link {
  cursor: pointer;
  -webkit-transition: all 0.2s;
  /* Safari prior 6.1 */
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
}
.link * {
  -webkit-transition: all 0.2s;
  /* Safari prior 6.1 */
  transition: all 0.2s ease-out;
}
.link:hover *:not(mat-icon) {
  text-decoration: underline;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

*[hidden], .hidden, .hide {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.position-relative {
  position: relative;
}

.round-corners {
  border-radius: 6px;
}

.border-radius-top-5 {
  border-radius: 5px 5px 0 0;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-30 {
  border-radius: 30px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-4 {
  padding: 4px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-8 {
  padding: 8px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-16 {
  padding: 16px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-24 {
  padding: 24px !important;
}

.padding-32 {
  padding: 32px !important;
}

.padding-48 {
  padding: 48px !important;
}

.padding-right-10 {
  padding-right: 10px;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.overflow-scroll {
  overflow: auto;
}

.overflow-scroll-x {
  overflow-x: auto;
}

.overflow-scroll-y {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text.headline {
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
}
.text.sub-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.text.body {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.text.label {
  font-size: 16px;
  font-weight: 600;
}
.text.label.secondary {
  font-size: 14px;
  font-weight: 500;
}
.text.italic {
  font-style: italic;
}
.text.align-left {
  text-align: left;
}
.text.align-center {
  text-align: center;
}
.text.align-right {
  text-align: right;
}
.text.font-large-headline {
  font-size: 3.5rem;
  font-weight: 700;
}
.text.font-body-xxlarge {
  font-size: 1.5rem;
}
.text.font-body-xlarge {
  font-size: 1.25rem;
}
.text.font-body-large {
  font-size: 1.125rem;
}
.text.font-body {
  font-size: 1rem;
}
.text.font-body-small {
  font-size: 0.875rem;
  font-weight: normal;
}
.text.font-body-xsmall {
  font-size: 0.75rem;
}
.text.font-body-xxsmall {
  font-size: 0.625rem;
}

.font-caption {
  font-size: 0.75rem;
}

.font-label {
  font-size: 0.875rem;
}

.font-question-label {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 4px;
}

.font-table-header {
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
}

.font-small-alert {
  font-size: 0.625rem;
  font-weight: 700;
}

.font-heading-1 {
  font-size: 2.5rem;
  font-weight: 700;
}

.font-heading-2 {
  font-size: 2.25rem;
  font-weight: 700;
}

.font-heading-3 {
  font-size: 2rem;
  font-weight: 700;
}

.font-heading-4 {
  font-size: 1.75rem;
  font-weight: 700;
}

.font-heading-5 {
  font-size: 1.5rem;
  font-weight: 700;
}

.font-heading-6 {
  font-size: 1.25rem;
  font-weight: 700;
}

.font-heading-7 {
  font-size: 1.125rem;
  font-weight: 700;
}

.font-heading-8 {
  font-size: 1rem;
  font-weight: 700;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.animate {
  -webkit-transition: all 2s;
  /* Safari prior 6.1 */
  transition: all 0.2s ease-out;
}

.disable-selection {
  user-select: none;
}
.disable-selection * {
  user-select: none;
}

.masking-gradient-top {
  position: relative;
}
.masking-gradient-top:after {
  content: "";
  display: block;
  width: calc(100% - 15px);
  height: 40px;
  position: absolute;
  top: -42px;
  left: 0;
  background: white;
  background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0.7) 60%, rgba(255, 255, 255, 0.2) 100%);
  z-index: 100;
  pointer-events: none;
}

.readonly,
[readonly],
.disabled,
[disabled] {
  cursor: not-allowed !important;
}
.readonly *,
[readonly] *,
.disabled *,
[disabled] * {
  cursor: not-allowed !important;
}

.full-width {
  width: 100%;
}

.shadow, .mat-paginator, .mat-table, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-text, .lozenge.rejected {
  color: #FF0000 !important;
}

.rag-red-bg {
  background-color: #FF0000 !important;
}

.rag-red-border, .lozenge.rejected {
  border-color: #FF0000 !important;
}

.rag-red-light-text {
  color: #FFB1B1;
}

.rag-red-light-bg, .lozenge.rejected {
  background-color: #FFB1B1;
}

.rag-amber-text, .lozenge.pending {
  color: #FF8900 !important;
}

.rag-amber-bg {
  background-color: #FF8900 !important;
}

.rag-amber-border, .lozenge.pending {
  border-color: #FF8900 !important;
}

.rag-amber-light-text {
  color: #FFF1DA;
}

.rag-amber-light-bg, .lozenge.pending {
  background-color: #FFF1DA;
}

.rag-blue-text, .lozenge.active {
  color: #56a5f8 !important;
}

.rag-blue-bg {
  background-color: #56a5f8 !important;
}

.rag-blue-border, .lozenge.active {
  border-color: #56a5f8 !important;
}

.rag-blue-light-text {
  color: #DDEBF6;
}

.rag-blue-light-bg, .lozenge.active {
  background-color: #DDEBF6;
}

.rag-green-text, .lozenge.complete {
  color: #80BE6C !important;
}

.rag-green-bg {
  background-color: #80BE6C !important;
}

.rag-green-border, .lozenge.complete {
  border-color: #80BE6C !important;
}

.rag-green-light-text {
  color: #D5F6E4;
}

.rag-green-light-bg, .lozenge.complete {
  background-color: #D5F6E4;
}

.white-text {
  color: #FFFFFF !important;
}

.white-bg {
  background-color: #FFFFFF !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EEECF3 !important;
}

.light-grey-bg {
  background-color: #EEECF3 !important;
}

.light-grey-border {
  border-color: #EEECF3 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.greyscale {
  filter: grayscale(100%);
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.lozenge {
  text-align: center;
  border: 1px solid;
  padding: 2px;
  border-radius: 20px;
}
.lozenge.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EEECF3;
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #FF8900;
}
.connect-status.approved {
  background-color: #80BE6C;
}
.connect-status.rejected {
  background-color: #FF0000;
}

button.mat-raised-button {
  border-radius: 6px;
  box-shadow: none !important;
  min-height: unset;
  min-width: unset;
  width: unset;
  vertical-align: middle;
  line-height: 18px;
  border-width: 2px;
  border-style: solid;
  font-weight: 700;
  outline: none;
  font-size: 14px;
  padding: 0px 16px;
  height: 42px;
}
button.mat-raised-button * {
  font-size: inherit;
}
button.mat-raised-button mat-icon {
  height: 20px;
  width: 20px;
  vertical-align: top;
  font-size: 20px;
  line-height: 16px;
}
button.mat-raised-button.xsmall {
  font-size: 10px;
  padding: 0px 8px;
  height: 24px;
  line-height: 14px;
}
button.mat-raised-button.xsmall mat-icon {
  height: 14px;
  width: 14px;
  vertical-align: top;
  font-size: 14px;
  line-height: 12px;
}
button.mat-raised-button.small {
  font-size: 12px;
  padding: 0px 12px;
  height: 36px;
  line-height: 16px;
}
button.mat-raised-button.small mat-icon {
  height: 18px;
  width: 18px;
  vertical-align: top;
  font-size: 18px;
  line-height: 14px;
}
button.mat-raised-button.large {
  font-size: 20px;
  padding: 0px 40px;
  height: 48px;
}
button.mat-raised-button.large mat-icon {
  height: 25px;
  width: 25px;
  vertical-align: top;
  font-size: 25px;
  line-height: 22px;
}
button.mat-raised-button.ghost {
  background-color: #FFFFFF;
}
button.mat-raised-button.ghost.rag-green:not(:disabled) {
  color: #80BE6C !important;
  border-color: #80BE6C !important;
}
button.mat-raised-button.ghost.rag-amber:not(:disabled) {
  color: #FF8900 !important;
  border-color: #FF8900 !important;
}
button.mat-raised-button.ghost.rag-red:not(:disabled) {
  color: #FF0000 !important;
  border-color: #FF0000 !important;
}
button.mat-raised-button.ghost.rag-blue:not(:disabled) {
  color: #56a5f8 !important;
  border-color: #56a5f8 !important;
}
button.mat-raised-button.borderless {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}
button.mat-raised-button:disabled {
  background-color: #EEECF3 !important;
  border-color: #EEECF3 !important;
  color: #B3ADB8 !important;
}
button.mat-raised-button:disabled.ghost {
  background-color: #FFFFFF !important;
  border-color: #EEECF3 !important;
  color: #B3ADB8 !important;
}
button.mat-raised-button:disabled.borderless {
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important;
  color: #B3ADB8 !important;
}
button.mat-raised-button.lozenge {
  border-radius: 100px;
  padding: 8px 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  height: 36px;
  vertical-align: middle;
}
button.mat-raised-button.lozenge mat-icon {
  padding-left: 8px;
  height: 14px;
  width: 14px;
  vertical-align: top;
  font-size: 14px;
}

/* TODO: Connect - do we need these fab buttons? */
button.mat-mini-fab {
  height: 30px;
  width: 30px;
}
button.mat-mini-fab .mat-button-wrapper {
  padding: 0;
}
button.mat-mini-fab .mat-button-wrapper .mat-icon {
  font-size: 18px;
  line-height: 18px;
}

.tabs {
  margin-bottom: -1px;
  border-bottom: 1px solid #B3ADB8;
}
.tabs .tab {
  -webkit-transition: all 0.2s;
  /* Safari prior 6.1 */
  transition: all 0.2s ease-out;
  margin-bottom: -1px;
  cursor: pointer;
  font-size: 14px;
  color: #B3ADB8;
  border: 1px solid #B3ADB8;
  background-color: #EEECF3;
  border-radius: 6px 6px 0 0;
  min-width: 158px;
  height: 41px;
  line-height: 41px;
  padding: 0 16px;
  vertical-align: middle;
  text-align: center;
}
.tabs .tab:not(:first-child) {
  margin-left: -1px;
}
.tabs .tab.active {
  font-weight: 700;
}

.shadow, .mat-paginator, .mat-table, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-text, .lozenge.rejected {
  color: #FF0000 !important;
}

.rag-red-bg {
  background-color: #FF0000 !important;
}

.rag-red-border, .lozenge.rejected {
  border-color: #FF0000 !important;
}

.rag-red-light-text {
  color: #FFB1B1;
}

.rag-red-light-bg, .lozenge.rejected {
  background-color: #FFB1B1;
}

.rag-amber-text, .lozenge.pending {
  color: #FF8900 !important;
}

.rag-amber-bg {
  background-color: #FF8900 !important;
}

.rag-amber-border, .lozenge.pending {
  border-color: #FF8900 !important;
}

.rag-amber-light-text {
  color: #FFF1DA;
}

.rag-amber-light-bg, .lozenge.pending {
  background-color: #FFF1DA;
}

.rag-blue-text, .lozenge.active {
  color: #56a5f8 !important;
}

.rag-blue-bg {
  background-color: #56a5f8 !important;
}

.rag-blue-border, .lozenge.active {
  border-color: #56a5f8 !important;
}

.rag-blue-light-text {
  color: #DDEBF6;
}

.rag-blue-light-bg, .lozenge.active {
  background-color: #DDEBF6;
}

.rag-green-text, .lozenge.complete {
  color: #80BE6C !important;
}

.rag-green-bg {
  background-color: #80BE6C !important;
}

.rag-green-border, .lozenge.complete {
  border-color: #80BE6C !important;
}

.rag-green-light-text {
  color: #D5F6E4;
}

.rag-green-light-bg, .lozenge.complete {
  background-color: #D5F6E4;
}

.white-text {
  color: #FFFFFF !important;
}

.white-bg {
  background-color: #FFFFFF !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EEECF3 !important;
}

.light-grey-bg {
  background-color: #EEECF3 !important;
}

.light-grey-border {
  border-color: #EEECF3 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.greyscale {
  filter: grayscale(100%);
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.lozenge {
  text-align: center;
  border: 1px solid;
  padding: 2px;
  border-radius: 20px;
}
.lozenge.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EEECF3;
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #FF8900;
}
.connect-status.approved {
  background-color: #80BE6C;
}
.connect-status.rejected {
  background-color: #FF0000;
}

*:not(.mat-icon) {
  font-family: "Inter", sans-serif;
}

.mat-checkbox *:not(mat-icon),
.mat-card *:not(mat-icon),
.mat-button *:not(mat-icon),
.mat-raised-button *:not(mat-icon),
.mat-icon-button *:not(mat-icon),
.mat-stroked-button *:not(mat-icon),
.mat-flat-button *:not(mat-icon),
.mat-fab *:not(mat-icon),
.mat-mini-fab *:not(mat-icon),
.mat-list-item *:not(mat-icon),
.mat-tab-group *:not(mat-icon) {
  font-family: "Inter", sans-serif;
}

.mat-icon {
  color: inherit;
  margin-top: 2px;
}

.mat-form-field {
  width: 100%;
}
.mat-form-field.tertiary .mat-form-field-infix {
  padding: 0px 0 6px 0;
  border-top: 11px solid transparent;
}
.mat-form-field.tertiary .mat-form-field-wrapper {
  padding: 0;
  margin: 0;
}
.mat-form-field.tertiary .mat-form-field-wrapper input {
  font-size: 14px;
  line-height: 16px;
}
.mat-form-field .mat-form-field-infix {
  padding: 15px 0 13px 0 !important;
  border-top: 0;
}
.mat-form-field .mat-form-field-wrapper {
  font-size: 14px !important;
  line-height: 16px;
}
.mat-form-field .mat-form-field-wrapper .mat-error {
  font-size: 10px !important;
}
.mat-form-field:not(:disabled) .mat-form-field-outline .mat-form-field-outline-start {
  background-color: #FFFFFF;
}
.mat-form-field:not(:disabled) .mat-form-field-outline .mat-form-field-outline-gap {
  background-color: #FFFFFF;
}
.mat-form-field:not(:disabled) .mat-form-field-outline .mat-form-field-outline-end {
  background-color: #FFFFFF;
}
.mat-form-field.mat-form-field-disabled.mat-focused .mat-form-field-label {
  color: #B3ADB8;
}
.mat-form-field.mat-form-field-disabled:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
  border-top: 1px solid #B3ADB8;
}
.mat-form-field.mat-form-field-disabled .mat-form-field-outline .mat-form-field-outline-start {
  border-left: 1px solid #B3ADB8;
  border-top: 1px solid #B3ADB8;
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.mat-form-field-disabled .mat-form-field-outline .mat-form-field-outline-gap {
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.mat-form-field-disabled .mat-form-field-outline .mat-form-field-outline-end {
  border-right: 1px solid #B3ADB8;
  border-top: 1px solid #B3ADB8;
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.mat-form-field-disabled mat-label {
  color: #B3ADB8;
}
.mat-form-field.light:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
  border-top: 1px solid #B3ADB8;
}
.mat-form-field.light .mat-form-field-outline .mat-form-field-outline-start {
  border-left: 1px solid #B3ADB8;
  border-top: 1px solid #B3ADB8;
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.light .mat-form-field-outline .mat-form-field-outline-gap {
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.light .mat-form-field-outline .mat-form-field-outline-end {
  border-right: 1px solid #B3ADB8;
  border-top: 1px solid #B3ADB8;
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field:not(.mat-form-field-disabled):not(.light).mat-focused .mat-form-field-outline .mat-form-field-outline-start, .mat-form-field:not(.mat-form-field-disabled):not(.light):hover .mat-form-field-outline .mat-form-field-outline-start {
  border-left-width: 2px !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}
.mat-form-field:not(.mat-form-field-disabled):not(.light).mat-focused .mat-form-field-outline .mat-form-field-outline-gap, .mat-form-field:not(.mat-form-field-disabled):not(.light):hover .mat-form-field-outline .mat-form-field-outline-gap {
  border-bottom-width: 2px !important;
}
.mat-form-field:not(.mat-form-field-disabled):not(.light).mat-focused .mat-form-field-outline .mat-form-field-outline-end, .mat-form-field:not(.mat-form-field-disabled):not(.light):hover .mat-form-field-outline .mat-form-field-outline-end {
  border-right-width: 2px !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}
.mat-form-field:not(.mat-form-field-disabled):not(.light).mat-focused:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap, .mat-form-field:not(.mat-form-field-disabled):not(.light):hover:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
  border-top-width: 2px !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #FF0000 !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-start {
  border-left: 1px solid #FF0000;
  border-top: 1px solid #FF0000;
  border-bottom: 1px solid #FF0000;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-gap {
  border-bottom: 1px solid #FF0000;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-end {
  border-right: 1px solid #FF0000;
  border-top: 1px solid #FF0000;
  border-bottom: 1px solid #FF0000;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-start {
  background-color: #FFFFFF;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-gap {
  background-color: #FFFFFF;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-end {
  background-color: #FFFFFF;
}
.mat-form-field.mat-form-field-invalid:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
  border-top: 1px solid #FF0000;
}
.mat-form-field button {
  position: absolute;
  right: 0;
  top: 2px;
}

.mat-tab-group {
  border-radius: 6px;
  background-color: #FFFFFF;
  overflow: hidden;
}
.mat-tab-group .mat-tab-label {
  font-weight: 700;
  opacity: 1;
}
.mat-tab-group .mat-tab-label.mat-tab-disabled {
  color: #B3ADB8 !important;
}

.mat-checkbox .mat-checkbox-inner-container {
  width: 24px;
  height: 24px;
}

.mat-checkbox-label {
  word-spacing: normal !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
  background-color: transparent !important;
}

.mat-slide-toggle span {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
}

.mat-slide-toggle.mat-checked.mat-slide-toggle-white.mat-primary .mat-slide-toggle-thumb {
  background-color: #FFFFFF !important;
}
.mat-slide-toggle.mat-checked.mat-slide-toggle-white.mat-primary .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38) !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #80BE6C !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(128, 190, 108, 0.38) !important;
}

.mat-slide-toggle.mat-slide-toggle-white .mat-slide-toggle-label {
  color: #FFFFFF;
}

.mat-slide-toggle.mat-slide-toggle-reverse .mat-slide-toggle-label {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.mat-progress-bar.green {
  height: 6px !important;
}
.mat-progress-bar.green .mat-progress-bar-fill::after {
  background-color: #80BE6C;
}
.mat-progress-bar.green .mat-progress-bar-buffer {
  background-color: #EEECF3;
}

.cdk-overlay-pane {
  max-width: 100% !important;
}
@media screen and (max-width: 599px) {
  .cdk-overlay-pane {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
  }
}

.mat-dialog-content {
  margin: 0 !important;
}

.mat-dialog-container {
  padding: 0 !important;
}

@media screen and (max-width: 599px) {
  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    transform: none !important;
    left: 16px !important;
    right: 16px !important;
    bottom: 0 !important;
    width: auto !important;
    height: auto !important;
    max-height: 80% !important;
  }
  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-select-panel {
    min-width: calc(100% - 30px) !important;
  }
}
.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.mat-select-arrow-wrapper {
  transform: none !important;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0.4em !important;
}

.mat-table .mat-header-cell .mat-sort-header-stem {
  display: none;
}
.mat-table .mat-header-cell .mat-sort-header-indicator {
  top: 3px;
}
.mat-table .mat-header-cell[aria-sort=descending] .mat-sort-header-indicator {
  top: -3px;
}

*:not(.mat-icon) {
  font-family: "Inter", sans-serif;
}

.mat-menu-panel {
  min-height: auto !important;
}
.mat-menu-panel .help-menu {
  border-radius: 10px;
  padding: 15px;
}
.mat-menu-panel .help-menu h4 {
  font-size: 18px;
  margin: 0 0 10px 0;
  padding-bottom: 10px;
}
.mat-menu-panel .help-menu span {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
}

.mat-menu-item {
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

a.mat-menu-item:hover {
  text-decoration: none;
}

.mat-menu-panel.wider-mat-menu {
  max-width: 330px;
}

.cdk-overlay-container .cdk-global-overlay-wrapper {
  align-items: center !important;
  justify-content: center !important;
}

.mat-snack-bar-container.alert-info {
  background-color: #EEECF3;
}
.mat-snack-bar-container.alert-warning {
  background-color: #FF8900;
}
.mat-snack-bar-container.alert-success {
  background-color: #80BE6C;
}
.mat-snack-bar-container.alert-error {
  background-color: #FF0000;
}
.mat-snack-bar-container .notification {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}
.mat-snack-bar-container .notification .content {
  max-width: 85%;
  margin-right: 30px;
}
.mat-snack-bar-container .notification .content label {
  font-size: 20px;
  font-weight: 600;
}
.mat-snack-bar-container .notification .content p {
  margin: 0;
}
.mat-snack-bar-container .notification .mat-icon.type {
  font-size: 40px;
  margin-right: 15px;
  height: 40px;
  width: 40px;
}
.mat-snack-bar-container .notification .mat-icon.close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.mat-snack-bar-container .notification .mat-icon.close:hover {
  cursor: pointer;
}

.shadow, .mat-paginator, .mat-table, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-text, .lozenge.rejected {
  color: #FF0000 !important;
}

.rag-red-bg {
  background-color: #FF0000 !important;
}

.rag-red-border, .lozenge.rejected {
  border-color: #FF0000 !important;
}

.rag-red-light-text {
  color: #FFB1B1;
}

.rag-red-light-bg, .lozenge.rejected {
  background-color: #FFB1B1;
}

.rag-amber-text, .lozenge.pending {
  color: #FF8900 !important;
}

.rag-amber-bg {
  background-color: #FF8900 !important;
}

.rag-amber-border, .lozenge.pending {
  border-color: #FF8900 !important;
}

.rag-amber-light-text {
  color: #FFF1DA;
}

.rag-amber-light-bg, .lozenge.pending {
  background-color: #FFF1DA;
}

.rag-blue-text, .lozenge.active {
  color: #56a5f8 !important;
}

.rag-blue-bg {
  background-color: #56a5f8 !important;
}

.rag-blue-border, .lozenge.active {
  border-color: #56a5f8 !important;
}

.rag-blue-light-text {
  color: #DDEBF6;
}

.rag-blue-light-bg, .lozenge.active {
  background-color: #DDEBF6;
}

.rag-green-text, .lozenge.complete {
  color: #80BE6C !important;
}

.rag-green-bg {
  background-color: #80BE6C !important;
}

.rag-green-border, .lozenge.complete {
  border-color: #80BE6C !important;
}

.rag-green-light-text {
  color: #D5F6E4;
}

.rag-green-light-bg, .lozenge.complete {
  background-color: #D5F6E4;
}

.white-text {
  color: #FFFFFF !important;
}

.white-bg {
  background-color: #FFFFFF !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EEECF3 !important;
}

.light-grey-bg {
  background-color: #EEECF3 !important;
}

.light-grey-border {
  border-color: #EEECF3 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.greyscale {
  filter: grayscale(100%);
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.lozenge {
  text-align: center;
  border: 1px solid;
  padding: 2px;
  border-radius: 20px;
}
.lozenge.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EEECF3;
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #FF8900;
}
.connect-status.approved {
  background-color: #80BE6C;
}
.connect-status.rejected {
  background-color: #FF0000;
}

*:not(.mat-icon) {
  font-family: "Inter", sans-serif;
}

.mat-table {
  width: 100%;
  font-family: "Inter", sans-serif !important;
  overflow: visible;
  border-radius: 4px;
}
.mat-table .mat-checkbox .mat-checkbox-inner-container {
  width: 12px;
  height: 12px;
}
.mat-table .mat-row:last-child {
  border-radius: 0 0 4px 4px;
}
.mat-table.has-paging .mat-row:last-child {
  border-radius: 0;
}
.mat-table .mat-header-row {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  border: 1px transparent;
  min-height: unset;
}
.mat-table .mat-header-row .mat-header-cell {
  text-transform: uppercase;
}
.mat-table .mat-header-cell, .mat-table .mat-cell {
  font-size: 12px;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: left;
}
.mat-table .mat-header-cell *:not(.mat-icon), .mat-table .mat-cell *:not(.mat-icon) {
  font-size: 12px;
}
.mat-table .mat-header-cell.center, .mat-table .mat-cell.center {
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}
.mat-table .mat-header-cell.right, .mat-table .mat-cell.right {
  justify-content: right;
}
.mat-table .mat-header-cell:last-of-type, .mat-table .mat-cell:last-of-type {
  border-right: 0;
}
.mat-table .mat-header-cell {
  font-weight: 700;
  height: 50px !important;
}
.mat-table .mat-row {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #EEECF3;
}
.mat-table .mat-row:hover:not(.no-hover-highlight) {
  background-color: #EEECF3;
}
.mat-table .mat-cell {
  font-weight: normal;
  border: none;
  overflow: hidden;
}

.mat-paginator {
  border-radius: 0 0 6px 6px;
}

.mat-tab-group .mat-tab-body {
  padding: 30px;
}
@media screen and (max-width: 959px) {
  .mat-tab-group .mat-tab-body {
    padding: 10px;
  }
}

.shadow, .mat-tab-group, .mat-table, .mat-paginator {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-text, .lozenge.rejected {
  color: #FF0000 !important;
}

.rag-red-bg {
  background-color: #FF0000 !important;
}

.rag-red-border, .lozenge.rejected {
  border-color: #FF0000 !important;
}

.rag-red-light-text {
  color: #FFB1B1;
}

.rag-red-light-bg, .lozenge.rejected {
  background-color: #FFB1B1;
}

.rag-amber-text, .lozenge.pending {
  color: #FF8900 !important;
}

.rag-amber-bg {
  background-color: #FF8900 !important;
}

.rag-amber-border, .lozenge.pending {
  border-color: #FF8900 !important;
}

.rag-amber-light-text {
  color: #FFF1DA;
}

.rag-amber-light-bg, .lozenge.pending {
  background-color: #FFF1DA;
}

.rag-blue-text, .lozenge.active {
  color: #56a5f8 !important;
}

.rag-blue-bg {
  background-color: #56a5f8 !important;
}

.rag-blue-border, .lozenge.active {
  border-color: #56a5f8 !important;
}

.rag-blue-light-text {
  color: #DDEBF6;
}

.rag-blue-light-bg, .lozenge.active {
  background-color: #DDEBF6;
}

.rag-green-text, .lozenge.complete {
  color: #80BE6C !important;
}

.rag-green-bg {
  background-color: #80BE6C !important;
}

.rag-green-border, .lozenge.complete {
  border-color: #80BE6C !important;
}

.rag-green-light-text {
  color: #D5F6E4;
}

.rag-green-light-bg, .lozenge.complete {
  background-color: #D5F6E4;
}

.white-text {
  color: #FFFFFF !important;
}

.white-bg {
  background-color: #FFFFFF !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EEECF3 !important;
}

.light-grey-bg {
  background-color: #EEECF3 !important;
}

.light-grey-border {
  border-color: #EEECF3 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.greyscale {
  filter: grayscale(100%);
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.lozenge {
  text-align: center;
  border: 1px solid;
  padding: 2px;
  border-radius: 20px;
}
.lozenge.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EEECF3;
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #FF8900;
}
.connect-status.approved {
  background-color: #80BE6C;
}
.connect-status.rejected {
  background-color: #FF0000;
}

.mat-tooltip {
  background-color: #EEECF3 !important;
  opacity: 1;
  font-size: 13px;
  padding: 25px 30px !important;
  max-width: 400px !important;
  box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.3);
  line-height: 20px;
}

.shadow, .mat-tab-group, .mat-table, .mat-paginator {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-text, .lozenge.rejected {
  color: #FF0000 !important;
}

.rag-red-bg {
  background-color: #FF0000 !important;
}

.rag-red-border, .lozenge.rejected {
  border-color: #FF0000 !important;
}

.rag-red-light-text {
  color: #FFB1B1;
}

.rag-red-light-bg, .lozenge.rejected {
  background-color: #FFB1B1;
}

.rag-amber-text, .lozenge.pending {
  color: #FF8900 !important;
}

.rag-amber-bg {
  background-color: #FF8900 !important;
}

.rag-amber-border, .lozenge.pending {
  border-color: #FF8900 !important;
}

.rag-amber-light-text {
  color: #FFF1DA;
}

.rag-amber-light-bg, .lozenge.pending {
  background-color: #FFF1DA;
}

.rag-blue-text, .lozenge.active {
  color: #56a5f8 !important;
}

.rag-blue-bg {
  background-color: #56a5f8 !important;
}

.rag-blue-border, .lozenge.active {
  border-color: #56a5f8 !important;
}

.rag-blue-light-text {
  color: #DDEBF6;
}

.rag-blue-light-bg, .lozenge.active {
  background-color: #DDEBF6;
}

.rag-green-text, .lozenge.complete {
  color: #80BE6C !important;
}

.rag-green-bg {
  background-color: #80BE6C !important;
}

.rag-green-border, .lozenge.complete {
  border-color: #80BE6C !important;
}

.rag-green-light-text {
  color: #D5F6E4;
}

.rag-green-light-bg, .lozenge.complete {
  background-color: #D5F6E4;
}

.white-text {
  color: #FFFFFF !important;
}

.white-bg {
  background-color: #FFFFFF !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EEECF3 !important;
}

.light-grey-bg {
  background-color: #EEECF3 !important;
}

.light-grey-border {
  border-color: #EEECF3 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.greyscale {
  filter: grayscale(100%);
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.lozenge {
  text-align: center;
  border: 1px solid;
  padding: 2px;
  border-radius: 20px;
}
.lozenge.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EEECF3;
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #FF8900;
}
.connect-status.approved {
  background-color: #80BE6C;
}
.connect-status.rejected {
  background-color: #FF0000;
}

app-dialog {
  height: 100%;
}

app-dialog.dialog-large-header-title .dialog-header-title {
  font-size: 32px;
}

.dialog-container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px;
  top: 0;
  left: 0;
}
.dialog-container h3.dialog-header-title {
  font-size: 18px;
  margin: 0;
  padding: 20px 0px 20px 20px;
}
.dialog-container .dialog-content {
  height: calc(100% - 160px);
  flex: 1 1;
  padding: 0 2px;
  overflow: auto;
  padding: 10px 20px 0px 20px;
}
.dialog-container .dialog-content [body] {
  height: 100%;
}
.dialog-container .borderless {
  padding: 0 !important;
  overflow: hidden !important;
}
.dialog-container .dialog-close {
  margin-right: 5px;
}
.dialog-container [footer-actions] {
  padding: 0px 20px 20px 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}
.dialog-container [footer-actions] > button {
  width: 100%;
}
@media screen and (min-width: 600px) {
  .dialog-container [footer-actions] > button {
    width: auto;
  }
}
.dialog-container [footer-actions].padding-top-8 {
  padding-top: 8px;
}

.shadow, .mat-tab-group, .mat-table, .mat-paginator {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-text, .lozenge.rejected {
  color: #FF0000 !important;
}

.rag-red-bg {
  background-color: #FF0000 !important;
}

.rag-red-border, .lozenge.rejected {
  border-color: #FF0000 !important;
}

.rag-red-light-text {
  color: #FFB1B1;
}

.rag-red-light-bg, .lozenge.rejected {
  background-color: #FFB1B1;
}

.rag-amber-text, .lozenge.pending {
  color: #FF8900 !important;
}

.rag-amber-bg {
  background-color: #FF8900 !important;
}

.rag-amber-border, .lozenge.pending {
  border-color: #FF8900 !important;
}

.rag-amber-light-text {
  color: #FFF1DA;
}

.rag-amber-light-bg, .lozenge.pending {
  background-color: #FFF1DA;
}

.rag-blue-text, .lozenge.active {
  color: #56a5f8 !important;
}

.rag-blue-bg {
  background-color: #56a5f8 !important;
}

.rag-blue-border, .lozenge.active {
  border-color: #56a5f8 !important;
}

.rag-blue-light-text {
  color: #DDEBF6;
}

.rag-blue-light-bg, .lozenge.active {
  background-color: #DDEBF6;
}

.rag-green-text, .lozenge.complete {
  color: #80BE6C !important;
}

.rag-green-bg {
  background-color: #80BE6C !important;
}

.rag-green-border, .lozenge.complete {
  border-color: #80BE6C !important;
}

.rag-green-light-text {
  color: #D5F6E4;
}

.rag-green-light-bg, .lozenge.complete {
  background-color: #D5F6E4;
}

.white-text {
  color: #FFFFFF !important;
}

.white-bg {
  background-color: #FFFFFF !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EEECF3 !important;
}

.light-grey-bg {
  background-color: #EEECF3 !important;
}

.light-grey-border {
  border-color: #EEECF3 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.greyscale {
  filter: grayscale(100%);
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.lozenge {
  text-align: center;
  border: 1px solid;
  padding: 2px;
  border-radius: 20px;
}
.lozenge.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EEECF3;
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #FF8900;
}
.connect-status.approved {
  background-color: #80BE6C;
}
.connect-status.rejected {
  background-color: #FF0000;
}