@import '~sass/font';
// Help Menu
.mat-menu-panel {
    min-height: auto !important;

    .help-menu {
        border-radius: 10px;
        padding: 15px;

        h4 {
            font-size: 18px;
            margin: 0 0 10px 0;
            padding-bottom: 10px;
        }

        span {
            display: block;
            font-size: 14px;
            font-weight: 700;
            margin-top: 20px;
        }
    }
}

.mat-menu-item {
    font-family: $font!important;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    padding: 0;
}

a.mat-menu-item:hover {
    text-decoration: none;
}

.mat-menu-panel.wider-mat-menu {
    max-width: 330px;
}