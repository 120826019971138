@import '~sass/breakpoints';
@import '~sass/color';
@import '~sass/font';
@import '~sass/mixins';

html {
    height: 100%;
}

body {
    font-family: $font;
    font-size: 1em;
    height: 100%;
    margin: 0;

    app-root {
        height: 100%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font;
}

h1.border,
h2.border,
h3.border,
h4.border,

a {
    text-decoration: underline;
    cursor: pointer;
}

section {
    margin-bottom: 30px;
}

.link {
    cursor: pointer;
    @include animate;

    display: flex;
    align-items: center;

    * {
        @include animate;
    }

    &:hover {
        *:not(mat-icon) {
            text-decoration: underline;
        }
    }
}

// Hide HTML5 Up and Down arrows
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

*[hidden], .hidden, .hide {
    display: none !important;
}

.pointer {
    cursor: pointer;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    border-radius: 4px;
}

.position-relative {
    position: relative;
}

.round-corners {
    border-radius: 6px;
}
.border-radius-top-5 {
    border-radius: 5px 5px 0 0;
}
.border-radius-10 {
    border-radius: 10px;
}
.border-radius-20 {
    border-radius: 20px;
}
.border-radius-30 {
    border-radius: 30px;
}

.margin-top-30 {
    margin-top: 30px;
}
.margin-top-24 {
    margin-top: 24px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-top-0 {
    margin-top: 0;
}
.margin-bottom-0 {
    margin-bottom: 0 !important;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}

.padding-4 {
    padding: 4px !important;
}

.padding-5 {
    padding: 5px !important;
}

.padding-8 {
    padding: 8px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-15 {
    padding: 15px !important;
}

.padding-16 {
    padding: 16px !important;
}

.padding-20 {
    padding: 20px !important;
}

.padding-24 {
    padding: 24px !important;
}

.padding-32 {
    padding: 32px !important;
}

.padding-48 {
    padding: 48px !important;
}

.padding-right-10 {
    padding-right: 10px;
}

.margin-top-16 {
    margin-top: 16px!important;
}

.overflow-scroll {
    overflow: auto;
}

.overflow-scroll-x {
    overflow-x: auto;
}

.overflow-scroll-y {
    overflow-y: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.text {
    &.headline {
        font-weight: 700;
        font-size: 24px;
        line-height: 22px;
    }

    &.sub-title {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
    }

    &.body {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
    }

    &.label {
        font-size: 16px;
        font-weight: 600;

        &.secondary {
            font-size: 14px;
            font-weight: 500;
        }
    }

    &.italic {
        font-style: italic;
    }

    &.align-left {
        text-align: left;
    }

    &.align-center {
        text-align: center;
    }

    &.align-right {
        text-align: right;
    }

    &.font-large-headline { font-size: 3.5rem; font-weight: 700; }
    &.font-body-xxlarge { font-size: 1.5rem; }
    &.font-body-xlarge { font-size: 1.25rem; }
    &.font-body-large { font-size: 1.125rem; }
    &.font-body { font-size: 1rem; }
    &.font-body-small { font-size: 0.875rem; font-weight: normal; }
    &.font-body-xsmall { font-size: 0.75rem; }
    &.font-body-xxsmall { font-size: 0.625rem; }    
}

.font-caption { font-size: 0.75rem; }
.font-label { font-size: 0.875rem; }
.font-question-label { font-size: 0.875rem; font-weight: 700; margin-bottom: 4px;}
.font-table-header { font-size: 0.625rem; font-weight: 600; text-transform: uppercase; }  
.font-small-alert { font-size: 0.625rem; font-weight: 700; } 

.font-heading-1 { font-size: 2.5rem; font-weight: 700; }
.font-heading-2 { font-size: 2.25rem; font-weight: 700; }
.font-heading-3 { font-size: 2rem; font-weight: 700; }
.font-heading-4 { font-size: 1.75rem; font-weight: 700; }
.font-heading-5 { font-size: 1.5rem; font-weight: 700; }
.font-heading-6 { font-size: 1.25rem; font-weight: 700; }
.font-heading-7 { font-size: 1.125rem; font-weight: 700; }
.font-heading-8 { font-size: 1rem; font-weight: 700; }

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.ellipsis {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.animate {
    -webkit-transition: all 2s; /* Safari prior 6.1 */
    transition: all 0.2s ease-out;
}

.disable-selection {
    user-select: none;

    * {
        user-select: none;
    }
}

.masking-gradient-top {
    position: relative;

    &:after {
        content: '';
        display: block;
        width: calc(100% - 15px);
        height: 40px;
        position: absolute;
        top: -42px;
        left: 0;
        background: rgb(255,255,255);
        background: linear-gradient( 0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 60%, rgba(255,255,255,0.2) 100%);
        z-index: 100;
        pointer-events: none;
    }
}

.readonly,
[readonly],
.disabled,
[disabled] {
    cursor: not-allowed !important;
    * {
        cursor: not-allowed !important;
    }
}

.full-width {
    width: 100%;
}